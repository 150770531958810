<script setup lang="ts">
import injectGoogleAnalytics from "~/utils/injectGoogleAnalytics";

injectGoogleAnalytics();

useSeoMeta({
  title: "grcschema.org - Common Data Format schemas",
  description: "Promotion Common Data Format schemas within the Governance, Risk, and Compliance sectors",
  ogDescription: "Promotion Common Data Format schemas within the Governance, Risk, and Compliance sectors",
});
</script>
<template>
  <div>
    <NuxtLayout name="app-layout">
      <div class="px-4 py-8 lg:px-12 lg:py-12">
        <slot />
      </div>
    </NuxtLayout>
  </div>
</template>
